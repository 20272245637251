import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import { useMediaQuery, Grid2 as Grid, Typography } from '@mui/material';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: "100%",
    // padding: "30px"
  },
}));

const Story = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid
        container
        justify="space-between"
        spacing={isMd ? 4 : 2}
        direction={isMd ? 'row' : 'column-reverse'}
      >
        <Grid
          item
          container
          alignItems="center"
          justify="flex-start"
          size={{ xs: 12, md: 6 }}
          data-aos={'fade-up'}
        >
          <div>
            <SectionHeader
              title="Our story"
              subtitle=""
              align="left"
              disableGutter
              subtitleProps={{
                color: 'textPrimary',
                variant: 'body1',
              }}
            />
            <Typography
              variant="body1"
              color="textPrimary"
              align="justify"
              sx={{ display: 'block', marginBottom: theme => theme.spacing(2) }}
              className=""
            >
              The digital foot print is every where.
            </Typography>
            <Typography
              variant="body1"
              align="justify"
              sx={{ display: 'block', marginBottom: theme => theme.spacing(2) }}
              color="textPrimary"
              className=""
            >
              The businesses and brands that are empowered with the technology know how and funds are able to capitalize the situation and serve the world through ecommerce, quick commerce platforms etc.
            </Typography>
            <Typography
              variant="body1"
              align="justify"
              sx={{ display: 'block', marginBottom: theme => theme.spacing(2) }}
              color="textPrimary"
              className=""
            >
              In this race the street vendors are left out to the mercy of good hearted people who want the local businesses to survive. The vendors may be is just 100 feet away but due to lack of digital presence the individuals who wish the service are not able to see them.
            </Typography>
            <Typography
              variant="body1"
              align="justify"
              sx={{ display: 'block', marginBottom: theme => theme.spacing(2) }}
              color="textPrimary"
              className=""
            >
              On the other hand there are shops, retailers, restaurants and service providers who want to attract customers with lucrative offers but they can see only when they reach the place.
            </Typography>
            <Typography
              variant="body1"
              align="justify"
              sx={{ display: 'block', marginBottom: theme => theme.spacing(2) }}
              color="textPrimary"
              className=""
            >
              Lokey is the platform trying to bridge the gap by helping vendors to make the location specific offers which can be viewed and availed by customers.
            </Typography>
            <Typography
              variant="h6"
              sx={{ display: 'block', marginBottom: theme => theme.spacing(2) }}
              color="textPrimary"
              className=""
            >
              This is just the beginning!
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          container
        
          justify={isMd ? 'flex-end' : 'flex-start'}
          alignItems="center"
          size={{ xs: 12, md: 6 }}
          data-aos={'fade-up'}
        >
          <Image
            src="/assets/aboutus/istockphoto-2022365808-2048x2048.jpg"
            alt="Our story"

            className={classes.image}
          />
        </Grid>
      </Grid>
    </div>
  );
};

Story.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Story;
