import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/styles';
import { Grid, Button, TextField, useMediaQuery } from '@mui/material';
import { SectionHeader } from 'components/molecules';
import { CardBase } from 'components/organisms';
import axios from "axios" ;
import { useSnackbar } from 'notistack';

const Subscription = props => {
  const { className, ...rest } = props;
  const [email, setEmail] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const onSubscribe = () => {
    axios.post('/sendSubscribeEmail', {email})
    .then (response => {
      if (response.statusText == "OK" || (response.status == 200 && response.statusText == "")){
        enqueueSnackbar('Email is subcribed for Newsletter.', {
          variant: 'success'
        });
        return ("OK");
      }
      else {
        enqueueSnackbar('Email subcription failed. Please send a mail to subscribe@mindsclik.com. ', {
          variant: 'error'
        });
      }
    })
    .catch((err)=>{
      enqueueSnackbar('Email subcription failed. Please send a mail to subscribe@mindsclik.com. ', {
        variant: 'error'
      });
    })
  }

  const handleTextFieldChange = (e) => {
       setEmail(e.target.value)
   }

  return (
    <div className={className} {...rest}>
      <CardBase withShadow>
        <SectionHeader
          title="Subscribe To Our Newsletter"
          subtitle="Be among the firsts to know about our upcoming news and updates."
          fadeUp
        />
        <Grid container spacing={1} alignItems="center" data-aos="fade-up">
          <Grid item xs={12} sm={9}>
            <TextField
              size="small"
              fullWidth
              label="Email"
              variant="outlined"
              value={email} onChange={handleTextFieldChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size={isMd ? 'large' : 'medium'}
              onClick = {onSubscribe}
            >
              subscribe
            </Button>
          </Grid>
        </Grid>
      </CardBase>
    </div>
  );
};

Subscription.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Subscription;
