import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const SectionHeaderRoot = styled(Grid)(({ theme, disableGutter }) => ({
  marginBottom: disableGutter ? 0 : theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    marginBottom: disableGutter ? 0 : theme.spacing(4),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
}));

const CTAItem = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  '&:first-child': {
    marginLeft: 0,
  },
}));

/**
 * Component to display the section headers
 *
 * @param {Object} props
 */
const SectionHeader = ({
  title,
  titleVariant='h3',
  subtitleVariant,
  subtitle,
  subtitleColor,
  label,
  overline,
  fadeUp,
  align,
  ctaGroup,
  disableGutter,
  titleClasses,
  className,
  labelProps={},
  titleProps={},
  subtitleProps={},
  ...rest
}) => {


  let justifyGrid = 'center';
  if (align === 'left') {
    justifyGrid = 'flex-start';
  } else if (align === 'right') {
    justifyGrid = 'flex-end';
  }

  return (
    <SectionHeaderRoot
      container
      spacing={2}
      data-aos={fadeUp ? 'fade-up' : ''}
      disableGutter={disableGutter}
      className={className}
      {...rest}
    >
      {overline && (
        <Grid
          item
          container
          justifyContent={justifyGrid}
          xs={12}
        >
          {overline}
        </Grid>
      )}
      {label && (
        <Grid item xs={12}>
          <Typography
            variant="overline"
            color="primary"
            align={align || 'center'}
            {...labelProps}
          >
            {label}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Title
          variant={titleVariant}
          align={align || 'center'}
          className={titleClasses}
          color="textPrimary"
          {...titleProps}
        >
          {title}
        </Title>
      </Grid>
      {subtitle && (
        <Grid item xs={12}>
          <Typography
            variant={subtitleVariant || 'h5'}
            align={align || 'center'}
            color={subtitleColor || 'textSecondary'}
            {...subtitleProps}
          >
            {subtitle}
          </Typography>
        </Grid>
      )}
      {ctaGroup && ctaGroup.length > 0 && (
        <Grid item xs={12}>
          <Grid
            container
            justifyContent={justifyGrid}
            alignItems="center"
            wrap="nowrap"
          >
            {ctaGroup.map((item, index) => (
              <CTAItem key={index}>
                {item}
              </CTAItem>
            ))}
          </Grid>
        </Grid>
      )}
    </SectionHeaderRoot>
  );
};

// SectionHeader.defaultProps = {
//   titleVariant: 'h4',
//   labelProps: {},
//   titleProps: {},
//   subtitleProps: {},
// };

SectionHeader.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Title of the section header
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  /**
   * Subtitle of the section header
   */
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * Label title of the section header
   */
  label: PropTypes.string,
  /**
   * The overline component in the section header
   */
  overline: PropTypes.node,
  /**
   * Array of the CTAs
   */
  ctaGroup: PropTypes.arrayOf(PropTypes.node),
  /**
   * Whether to fade up on scroll
   */
  fadeUp: PropTypes.bool,
  /**
   * Header content (title, subtitle, CTAs) alignment
   */
  align: PropTypes.oneOf(['right', 'left', 'center']),
  /**
   * Whether to disable bottom margin of the section
   */
  disableGutter: PropTypes.bool,
  /**
   * Styles classes to be attached to the title from the parent component
   */
  titleClasses: PropTypes.string,
  /**
   * Title variant
   */
  titleVariant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    // .isRequired,
  /**
   * SubTitle variant
   */
  subtitleVariant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
  ]),
  /**
   * SubTitle color
   */
  subtitleColor: PropTypes.oneOf([
    'textPrimary',
    'textSecondary',
    'primary',
    'secondary',
  ]),
  /**
   * Additional properties to pass to the label Typography component
   */
  labelProps: PropTypes.object,
  /**
   * Additional properties to pass to the title Typography component
   */
  titleProps: PropTypes.object,
  /**
   * Additional properties to pass to the subtitle Typography component
   */
  subtitleProps: PropTypes.object,
};

export default SectionHeader;
